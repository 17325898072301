<template>
    <div id="page-content">
        <div class="container container__infos">
            <div class="row p-3 infos d-flex justify-content-between">
                <div class="col-lg-3 p-3 infos__access d-flex flex-column">
                    <h3 class="mr-auto infos__access__heading">Venir au cabinet</h3>
                    <dl class="my-auto infos__access__content">
                        <dt class="ml-3 mt-3"><i class="las la-map-marked infos__access__content__icon--title"></i>
                            Adresse
                        </dt>
                        <dd>57, Rue Robert Desnos<br />31170 Tournefeuille</dd>
                        <dt class="mt-3 ml-3"><img class="infos__access__content__logo" src="@/assets/img/png/tisseo.png"
                                alt="Tisseo"> En bus </dt>
                        <dd>
                            <img class="infos__access__content__ligne" src="@/assets/img/png/ligne3.png" alt="Ligne 3">
                            Ligne 3
                            : Arrêt Saint Pierre<br />
                            <img class="infos__access__content__ligne" src="@/assets/img/png/ligne21.png" alt="Ligne 21">
                            Ligne
                            21 : Gymnase<br />
                        </dd>
                        <dt class="mt-3 ml-3"><i class="las la-car-alt infos__access__content__icon--title"></i> En
                            voiture
                        </dt>
                        <dd><i class="las la-road infos__access__content__icon"></i> Rocade Arc-en-ciel : Sortie 980.2
                        </dd>
                    </dl>
                </div>
                <div class="col-lg-4 mr-lg-3 p-3 d-flex flex-column text-lg-right infos__access ">
                    <h3 class="ml-lg-auto mr-auto mr-lg-0 text-left infos__access__heading">Infos Complémentaires</h3>
                    <dl class="my-auto infos__access__content">
                        <dt class="ml-3 mt-3">
                            <i class="las la-parking infos__access__content__icon--title"></i> Parking
                        </dt>
                        <dd>
                            Places de parking disponibles :<br />
                            <em>Rue Paul Valery<br />
                                Rue Robert Desnos, Paul Eluard...</em>
                        </dd>
                        <dt class="mt-3 ml-3"><i class="lab la-accessible-icon infos__access__content__icon--title"></i>
                            Accessibilité</dt>
                        <dd>
                            <i class="las la-wheelchair infos__access__content__icon"></i>Ascenseur 1er étage<br />
                            <i class="las la-parking infos__access__content__icon"></i>Parking prioritaire
                        </dd>
                    </dl>
                </div>
                <div class="p-3 col-lg-4 border border-primary shadow infos__map d-flex flex-column align-items-center">
                    <h3 class="text-white w-100">Plan d'accès</h3>
                    <iframe class="shadow-sm"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.934758066203!2d1.3311719762962388!3d43.5870752567048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aeb0f96797da6b%3A0xc6a55170898f7f29!2s57%20Rue%20Robert%20Desnos%2C%2031170%20Tournefeuille!5e0!3m2!1sen!2sfr!4v1704727909211!5m2!1sen!2sfr"
                        width="400" height="300" style="border:0;" allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe>
                </div>
            </div>
            <div class="row p-3 infos-sup d-flex justify-content-between align-items-center">
                <div class="col-lg-6 infos-sup__moyens">
                    <div class="m-2 m-lg-4 p-2 rounded text-light infos-sup__moyens__frame">
                        <h3>Moyens de paiement</h3>
                        <ul class="list-unstyled">
                            <li><img src="@/assets/img/svg/wallet.svg" alt="icone portefeuille"> Espèces - Chèques</li>
                            <li><img class="infos-sup__moyens__frame__icon" src="@/assets/img/png/nocb.png"
                                    alt="Pas de carte bleue"> Pas de carte-bleue</li>
                            <li><img class="infos-sup__moyens__frame__icon" src="@/assets/img/png/vitale.png"
                                    alt="Carte vitale"> Prenez votre carte vitale et mutuelle<br />(Tiers payant
                                pratiqué)
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 infos-sup__horaires-contact">
                    <div class="m-2 m-lg-4 p-2 rounded text-light infos-sup__horaires-contact__frame">
                        <h3>Horaires</h3>
                        <ul class="list-unstyled ml-2 ml-md-4">
                            <li>Du lundi au vendredi : 8h20 - 20h</li>
                            <li>Le samedi : 9h - 13h</li>
                        </ul>
                        <h3>Contact</h3>
                        <ul class="list-unstyled ml-2 ml-md-4">
                            <li><img src="@/assets/img/svg/telephone.svg" alt="icone téléphone"> Téléphone : <a
                                    href="tel:0561513480">05.61.51.34.80</a> / <a href="tel:0621687892">06.21.68.78.92</a>
                            </li>
                            <li><img src="@/assets/img/svg/letter.svg" alt="icone enveloppe"> Contact : <router-link
                                    to="/contact">Formulaire de contact</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
export default {
    name: 'Infos',
    methods: {
        ...mapActions(['updateActive'])
    },
    mounted() {
        this.updateActive(this.$route);
        document.title = 'Camille Barès - Informations pratiques';
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped></style>